var Helper = {
    randomString: function(length, chars) {
        var result = '';

        length = length || 8;
        chars = chars || '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!:@?$%';

        while ( result.length < length ) {
        	result += chars[Math.round(Math.random() * (chars.length - 1))];

        	var reg = /(.)(.*?)(\1)/g;
    		while (reg.test(result))
    			result = result.replace(reg, "$1$2");
        }
        return result;
    },

    filesizeHumanReadable: function(size) {
        var i = Math.floor( Math.log(size) / Math.log(1024) );
        return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    }
};
