$(function () {
	$(document).on('shown.bs.modal', function(e) {
	   if ( $(e.target).find('input[type="text"]').length > 0 )
	   	$(e.target).find('input[type="text"]').focus();
	});

	$(document).on('hidden.bs.modal', function(e) {
	   	$(e.target).find('input[type="text"]').val('');
	});
});
